import { createSlice } from '@reduxjs/toolkit';

const errorState = createSlice({
  name: 'errorState',
  initialState: {
    apiErrorList: {}
  },
  reducers: {
  	SET_API_ERROR: function(state, action) {
  		console.log(action);
  		const newState = {...state};
  		if(action.payload && action.payload.callerTrace) {
  			newState.apiErrorList = {...state.apiErrorList};
  			newState.apiErrorList[action.payload.callerTrace] = true;
  		}
  		return newState;
  	},
  	CLEAR_API_ERROR: function(state, action) {
  		const newState = {...state};
  		if(action.payload && action.payload.callerTrace) {
  			newState.apiErrorList = {...state.apiErrorList};
  			newState.apiErrorList[action.payload.callerTrace] = false;
  		}
  		return newState;
  	}
  },
});

// Export the action creators
export const { SET_API_ERROR, CLEAR_API_ERROR } = errorState.actions;
export default errorState.reducer;
